<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="invite-layout-spbi">
      <div>
        <img v-if="$t('invite.inviteImg')=='zh'" src="@/assets/images/invite/spbi001.png" style="width: 90%;margin-top: 10%;" />
        <img v-if="$t('invite.inviteImg')=='en'" src="@/assets/images/invite/spbi001-1.png" style="width: 90%;margin-top: 10%;" />
      </div>

      <div class="invite-footer" style="margin: 6% 3% 0 3%;background-color: rgba(0, 0, 0, 0.5);height: 190px;">
        <div style="height: 20px"></div>
        <div class="invite-code">
          {{$t('inviteFriend.i_code')}}：
          <div class="invite-code-text">
            <span @click="copyVal($event.target.innerText)" style="color: white;">{{inviteCode}}</span>
            <span @click="copyVal($event.currentTarget.previousElementSibling.innerText)" style="margin-left: 2%;">
              <img src="@/assets/common/copyIconW.png" style="width: 12px;margin-bottom: 20px;" />
            </span>
          </div>
        </div>

        <el-button v-loading="isLoadingBtn" element-loading-background="rgba(0, 0, 0, 0.5)" type="success" @click="goInvite()" class="piai-code-btn" style="position: relative;padding: 0;margin-bottom: 5%;">
          <img src="@/assets/images/invite/SugarPopBlastButton.png" style="width: 300px;"/>
          <span style="position: absolute;box-sizing: border-box;left: 0;right: 0;top: 3px;color: white;">
            <img src="@/assets/images/invite/SugarPopBlastIcon.png" style="width: 36px;vertical-align: middle;margin:5px 8px 3px 0;"/>
            <span style="font-size: 18px;font-weight: bold;vertical-align: middle;">
              {{appName}}
            </span>
          </span>
        </el-button>

      </div>

      <el-dialog :visible.sync="isDownloadDialog" width="76%" center>
        <div style="text-align: center;">
          <div style="font-size: 20px;color: black;">
            {{$t('invite.i_havaDownloaded')}}
            <br />
            “{{appName}}”？
          </div>
          <el-button round @click="goDownload()" style="background-color: rgba(0, 111, 255, 1);color: white;margin-top: 30px;width: 60%;">
            {{$t('invite.i_goDownload')}}
          </el-button>
          <div @click="goApp()" style="text-decoration: underline;margin-top: 20px;color: rgba(161, 161, 161, 1);">
            {{$t('invite.i_downloaded')}}
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        // 加载屏蔽层状态
        isLoading: false,
        isLoadingBtn: false,
        webAppId: 19,
        inviteCode: "",
        isDownloadDialog: false,
        appName:"Sugar Pop Blast"
      };
    },
    mounted() {
      document
        .querySelector("body")
        .setAttribute("style", "background: linear-gradient(0deg,#4E56C9 0%,#4E56C9 10%,#3EC084 100%);");
    },
    beforeDestroy() {
      document
        .querySelector("body")
        .removeAttribute("style", "background: linear-gradient(0deg,#4E56C9 0%,#4E56C9 10%,#3EC084 100%);");
    },
    methods: {
      copyVal(val) {
        // 创建一个 Input标签
        let oInput = document.createElement("input");
        oInput.value = val;
        document.body.appendChild(oInput);
        // 选择对象
        oInput.select();
        // 执行浏览器复制命令
        // 复制命令会将当前选中的内容复制到剪切板中
        // 如这里构建的 Input标签
        document.execCommand("Copy");
        this.$message({
          message: this.$t("common.i_copy"),
          type: "success",
          showClose: true,
          center: true
        });
        // 复制成功后再将构造的标签 移除
        oInput.remove();
      },
      goInvite() {
        this.isLoadingBtn = true;
        this.$axios({
          url: "/webGift/invite",
          method: "get",
          params: {
            webAppId: this.webAppId
          }
        }).then((result) => {
          this.isLoadingBtn = false;
          if (result.data.code == 1) {
            this.isDownloadDialog = true;
          } else {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
          }
        });
      },
      goDownload() {
        window.location.href =
          "https://flymodensoft.s3.ap-northeast-1.amazonaws.com/SugarPopBlast.html?referrer=" +
          this.inviteCode;
      },
      goApp() {
        window.location.href = "sugarpopblast://webgf";
      }
    },
    created() {
      this.isLoading = true;
      this.$axios({
        url: "/webGift/invite",
        method: "get",
        params: {
          webAppId: this.webAppId
        }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
      this.inviteCode = this.$route.query.referrer;
    }
  };
</script>

<style>
  #invite-layout-spbi {
    width: 100%;
    height: 100%;
    background: linear-gradient(
        0deg,
        #4E56C9 0%,
        #4E56C9 10%,
        #3EC084 100%
    );
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  #invite-layout-spbi .invite-code {
    margin-top: 10px;
    margin-bottom: 10%;
    font-size: 20px;
    color: white;
  }
  #invite-layout-spbi .invite-code-text {
    color: rgba(19, 100, 62, 1);
    display: inline;
  }
  #invite-layout-spbi .el-loading-mask {
    border-radius: 30px;
  }
  #invite-layout-spbi .el-dialog {
    border-radius: 15px !important;
  }
  #invite-layout-spbi .el-dialog__header {
    background: white !important;
    border-radius: 15px 15px 0 0 !important;
  }
  #invite-layout-spbi .el-dialog__body {
    background: white !important;
    border-radius: 0 0 15px 15px !important;
  }
  #invite-layout-spbi .piai-code-btn {
    border: none !important;
    background: none !important;
  }
</style>
